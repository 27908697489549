.row {
  max-width: 800px;
  margin: 24px auto;
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 0 30px;
  position: relative;
}

.row--heading {
  margin-top: 0;
  margin-bottom: 0;
}

.right {
  position: absolute;
  right: 0px;
  margin: 10px;
}

.icon {
  margin: 10px;
}

.container {
  display: flex;
}
